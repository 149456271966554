export function personNameFormalize(language, nameString){
	if(language != 'lt') return nameString;

	const suffixes = {
		'a': 'a',
		'as': 'ai',
		'ė': 'e',
		'is': 'i',
		'us': 'au',
		'ys': 'y'
	}

	// Parse names. There could be more than one
	let names = nameString.trim().split(" ")

	let output = []
	// Replace every ending of the name according suffixes map
	names.forEach(str => {
		_.forEach(suffixes, (to, from) => {
			if(str.endsWith(from)){
				str = str.replace(new RegExp(from + "$"), to)
			}
		})

		output.push(str)
	})
	
	return output.join(" ")
}

export function load(language, component, signature){
	// GET request
	return axios.get('/translations/main.' + language + '.json?'  + (signature ? signature : Math.random()))
		.then(response => {
			component.t = response.data
		})
}